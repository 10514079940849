$unit: 240px;
$thick: 12px;

$colors: #ff0013, #ff9713, #ffe213, #2dce13, #47c6ff, #5900ab;

.loading-wrapper {
  .rainbow {
    width: $unit;
    height: $unit/2;
    min-width: 150px;
    min-height: 75px;
    overflow: hidden;
    position: relative;

    .bow {
      position: absolute;
      // transform: rotate(-45deg);
      top: 0;
      left: 0;
      right: 0;
      height: $unit;
      min-height: 150px;
      box-sizing: content-box;

      &:before {
        content: "";
        position: absolute;
        border: $thick solid transparent;
        transform: rotate(-45deg);
        border-radius: 50%;
      }

      @for $i from 1 to 7 {
        $bow: ($i - 1) * $thick;

        &:nth-of-type(#{$i}) {
          animation: bow
            2.5s
            cubic-bezier(0.46, 0.1, 0.25, 1)
            infinite
            $i/18 *
            1s;
          &:before {
            top: $bow;
            right: $bow;
            bottom: $bow;
            left: $bow;
            border-bottom-color: nth($colors, $i);
            border-left-color: nth($colors, $i);
          }
        }
      }
    }
  }

  @keyframes bow {
    0%,
    5% {
      transform: rotate(0);
    }
    40%,
    60% {
      transform: rotate(180deg);
    }
    95%,
    100% {
      transform: rotate(360deg);
    }
  }
}

.loading-dots {
  p span:before {
    animation: dots 2s linear infinite;
    content: "";
  }
  @keyframes dots {
    0%,
    20% {
      content: ".";
    }
    40% {
      content: "..";
    }
    60% {
      content: "...";
    }
    90%,
    100% {
      content: "";
    }
  }
}
