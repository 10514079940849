@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;500;800&display=swap");

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

button,
textarea,
input,
select,
div.swiper-slide,
a {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  text-align: center;
}

body:after {
  content: "";
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
  z-index: -1;
  background: #f8e4db url("./images/bg-website.jpg") center center;
  background-size: cover;
}

html,
body,
#root,
.app {
  height: 100%;
}

.app {
  display: flex;
  min-height: 100%;
}

.th-serif {
  font-family: "Noto Sans Thai", sans-serif;
}

.bg-rainbow {
  transition: all 0.3s;
  background-image: linear-gradient(
    45deg,
    rgba(255, 149, 0, 1) 0%,
    rgba(218, 95, 255, 1) 50%,
    rgb(41, 114, 250) 100%
  );
  background-size: 100% 300%;
  background-position: 0 100%;
}

.bg-rainbow:hover {
  background-position: -100% 0;
}

.markdown-text {
  a {
    display: inline-block;
    @apply text-pink-700;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0.5rem 0 0.125rem;
    line-height: 1.325;
    @apply text-gray-700;
  }

  a[title="buy"] {
    @apply mr-2 mb-2 text-lg rounded-xl inline-block px-4 py-2 bg-pink-100;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.375;
  }

  img {
    max-width: 120px;
    height: auto;
  }
}

.card-rainbow {
  cursor: pointer;

  .card-wrapper {
    transition: all 0.2s;
    background-color: #fff;
    overflow: hidden;

    .card-wrapper-inner {
      width: 100%;
      border: 3px solid #93a4ad;
      overflow: hidden;
    }

    .card-inner {
      background-image: url("./images/card-back.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      transform: scale(1);
      transition: all 0.3s;
    }

    .card-checked {
      opacity: 0;
      transition: all 0.3s;
      transform: translateY(-15%);
    }

    &:hover {
      .card-wrapper-inner {
        border: 3px solid #333;
      }
      .card-inner {
        transform: scale(1.05);
      }
      .card-checked {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.switch-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  -webkit-overflow-scrolling: touch;

  .switch-absolute {
    position: absolute;
    width: 100%;
    height: 100%;

    .view {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      justify-content: center;
      min-height: 100%;
      padding: 30px 0;
      overflow: hidden;
    }
  }
}

.loading-spinner {
  border: 0.4rem solid rgba(0, 0, 0, 0.15);
  border-top: 0.4rem solid rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.fade-enter {
  opacity: 0;
  transform: translateY(-10%) scale(0.9);
  z-index: 9;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0) scale(1);
  transition: all 500ms ease-in-out;
  z-index: 9;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0) scale(1);
  z-index: 5;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translateY(10%) scale(0.9);
  transition: all 500ms ease-in-out;
  z-index: 5;
}

@keyframes scaleUp {
  0% {
    transform: scale(0.8) translateY(5%) rotateY(180deg);
    opacity: 0;
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1) translateY(0) rotateY(0);
    opacity: 1;
  }
}

.anim-scale-up {
  animation: scaleUp 0.8s ease-in-out forwards;
  transform-style: preserve-3d;
}
